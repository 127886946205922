<template>
  <div class="widget-form__container widget-form__container_links">
    <h4 class="widget-form__title">Выберите каналы связи</h4>
    <div class="widget-form__links">
      <ul class="widget-form__fields">

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: whatsApp.checked }"
            >
              WhatsApp
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_whatsapp_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="whatsapp" v-model="whatsApp.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                v-maska="'+7 (###) ###-##-##'"
                type="text"
                v-model="whatsApp.value"
                id="whatsapp_phone"
                name="WhatsApp Phone"
                required="required"
                class="widget-form__input required"
                @blur="validatePhone(whatsApp)"
                v-bind:class="{ _hasError: whatsApp.error }"
            >
               <label for="whatsapp_phone" class="widget-form__label text__field-label">
              Укажите номер
            </label>
          </span>

          <ItemErrors :errors="whatsApp.error"/>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: mobilePhone.checked }"
            >
              Телефон
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_phone_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="phone" v-model="mobilePhone.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                v-maska="'+7 (###) ###-##-##'"
                type="text"
                v-model="mobilePhone.value"
                id="order_phone"
                name="phone"
                required="required"
                class="widget-form__input required"
                @blur="validatePhone(mobilePhone)"
                v-bind:class="{ _hasError: mobilePhone.error }"
            >
            <label for="order_phone" class="widget-form__label text__field-label">
              Укажите номер
            </label>
          </span>
          <ItemErrors :errors="mobilePhone.error"/>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: viberPhone.checked }"
            >
              Viber
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_viber_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="viber" v-model="viberPhone.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                v-maska="'+7 (###) ###-##-##'"
                type="text"
                v-model="viberPhone.value"
                id="order_phone"
                name="phone"
                required="required"
                class="widget-form__input required"
                @blur="validatePhone(viberPhone)"
                v-bind:class="{ _hasError: viberPhone.error }"
            >
             <label for="order_phone" class="widget-form__label text__field-label">
              Укажите номер
            </label>
          </span>
          <ItemErrors :errors="viberPhone.error"/>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: email.checked }"
            >
                   Email
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_mail_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="email" v-model="email.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                type="text"
                v-model="email.value"
                id="email"
                name="phone"
                required="required"
                class="widget-form__input required"
                @blur="validateEmail"
                v-bind:class="{ _hasError: email.error }"
            >
             <label for="order_phone" class="widget-form__label text__field-label">
              Укажите почтовый адрес
            </label>
          </span>

          <ItemErrors :errors="email.error"/>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: telegram.checked }">
              Telegram
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_telegram_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="telegram" v-model="telegram.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                type="text"
                v-model="telegram.value"
                id="order_phone"
                name="phone"
                required="required"
                class="widget-form__input required"
            >
            <label for="order_phone" class="widget-form__label text__field-label">
              Укажите название аккаунта
            </label>
          </span>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field-head">
            <span class="widget-form__field-name"
                  v-bind:class="{_checked: vk.checked }">
              Вконтакте
            </span>
            <span class="widget-form__hint">
              ?
            </span>
            <span class="widget-form__hint-modal">
                По идентификатору JB_vk_click отправится цель в счетчики Я.Метрики и Google Analitics
            </span>
<!--            <WidgetCheckbox id="vk" v-model="vk.checked"></WidgetCheckbox>-->
          </span>
          <span class="widget-form__field">
            <input
                type="text"
                v-model="vk.value"
                id="order_phone"
                name="phone"
                required="required"
                class="widget-form__input required"
            >
            <label for="order_phone" class="widget-form__label text__field-label">
              Укажите название аккаунта
            </label>
          </span>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>

import { mapWritableState} from "pinia";
import {useWidgetStore} from "../stores/modules/widget";
import { maska } from 'maska'
import WidgetCheckbox from "./WidgetCheckbox.vue";
import ItemErrors from "./ItemErrors.vue";

export default {
  name: "WidgetLinks",
  components: {ItemErrors, WidgetCheckbox},
  computed: {
    ...mapWritableState(useWidgetStore, [
        'whatsApp',
        'mobilePhone',
        'viberPhone',
        'email',
        'telegram',
        'vk',
        'errors'
    ]),
  },
  methods: {
    validateEmail() {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!this.email.value.match(validRegex)) {
        this.email.error = 'Некорректный e-mail';
      } else {
        this.email.error = '';
      }
    },
    validatePhone(component) {
      const validRegex = /^.{18}$/;
      if (!component.value.match(validRegex)) {
        component.error = 'Некорректный телефон';
      } else {
        component.error = '';
      }
    },
  },
  directives: {
    maska
  },
}
</script>

<style scoped>

</style>