import {onDomReady} from "../../components/dynamic/observer";
import {Swiper, Autoplay, EffectCreative} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const swipeSliderElement = document.querySelector('[data-swipe-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const slider = new Swiper(swipeSliderElement, {
        modules: [Autoplay, EffectCreative],
        speed: 500,
        direction: "vertical",
        slidesPerView: 3,
        loop: true,
        autoplay: {
            delay: 1300,
            disableOnInteraction: false,
        },
    });
}

onDomReady(init);