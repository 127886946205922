export class ScrollSlider {
    constructor(rootElement)
    {
        this.rootElement = rootElement;
        this.lastUpdateTopPosition = null;
        this.rootTop = undefined;
        this.rootHeight = undefined;
        this.slides = [];
        this.activeSlide = null;
        this.bullets = [];
        this.activeBullet = null;
        this.initSlides();
        this.initBullets();
        this.bind();
    }

    bind()
    {
        window.addEventListener('scroll', () => {
            this.onScroll();
        });
    }

    initSlides()
    {
        this.rootElement.querySelectorAll('[data-scroll-slide]').forEach((slide) => {
            this.slides.push(slide);
        });
    }

    initBullets()
    {
        document.querySelectorAll('[data-scroll-slide-bullet]').forEach((bullet) => {
            if (bullet) {
                this.bullets.push(bullet);
            }
        });
    }

    updateRootPositionInfo()
    {
        const rect = this.rootElement.getBoundingClientRect();
        this.rootTop = rect.top
        + document.documentElement.scrollTop;
        this.rootHeight = rect.height;
    }

    getRootPosition()
    {
        const now = new Date();
        if (!this.lastUpdateTopPosition || (now - this.lastUpdateTopPosition) > 500) {
            this.lastUpdateTopPosition = now;
            this.updateRootPositionInfo();
        }
        return {
            top: this.rootTop,
            height: this.rootHeight,
        };
    }

    onScroll()
    {
        const position = this.getRootPosition();
        const pageHeight = window.innerHeight;
        let scrolled = document.documentElement.scrollTop - position.top;
        const maxScroll = position.height - pageHeight;
        if (scrolled < 0) {
            scrolled = 0;
        }
        if (scrolled > maxScroll) {
            scrolled = maxScroll;
        }
        const activeSlide = Math.floor(scrolled / pageHeight);
        const percent = (scrolled - activeSlide * pageHeight) / pageHeight * 100;

        const currentSlideElement = this.slides[this.activeSlide];
        const activeSlideElement = this.slides[activeSlide];
        const currentSlideBullet = this.bullets[this.activeBullet];
        const activeSlideBullet = this.bullets[activeSlide];
        let nextSlideElement = null;
        let nextSlideBullet = null;
        if (activeSlide + 1 < this.slides.length) {
            nextSlideElement = this.slides[activeSlide + 1];
            nextSlideBullet = this.bullets[activeSlide + 1];
        }

        this.setPercent(activeSlideElement, percent);
        this.setPercent(activeSlideBullet, percent);
        if (this.activeSlide !== null) {
            if (this.activeSlide > activeSlide) {
                this.setPercent(currentSlideElement, 0);
            } else if (this.activeSlide < activeSlide) {
                this.setPercent(currentSlideElement, 100);
            }
        }
        if (this.activeBullet !== null) {
            if (this.activeBullet > activeSlide) {
                this.setPercent(currentSlideBullet, 0);
            } else if (this.activeBullet < activeSlide) {
                this.setPercent(currentSlideBullet, 100);
            }
        }

        const firstSlideElement = this.slides[0];
        if (nextSlideElement) {
            if (Math.floor(percent) > 59) {
                if (!nextSlideElement.classList.contains('_animate-in')) {
                    nextSlideElement.classList.add('_animate-in');
                }
            }

            if (!firstSlideElement.classList.contains('_animate-in')) {
                firstSlideElement.classList.add('_animate-in');
            }
            if (firstSlideElement.classList.contains('_animate-out')) {
                firstSlideElement.classList.remove('_animate-out');
            }
        }

        if (nextSlideElement) {
            this.setPercent(nextSlideElement, percent, 'next-percent');
        }

        if (nextSlideBullet) {
            this.setPercent(nextSlideBullet, percent, 'next-percent');
        }

        this.setActiveSlide(activeSlide);
    }

    setActiveSlide(newActiveSlide)
    {
        if (newActiveSlide === this.activeSlide) {
            return;
        }
        const newActiveSlideElement = this.slides[newActiveSlide];
        const newActiveSlideBullet = this.bullets[newActiveSlide];
        const newActiveElements = [newActiveSlideElement, newActiveSlideBullet];

        let direction = 'down';
        if (newActiveSlide < this.activeSlide) {
            direction = 'up';
        }

        if (this.activeSlide !== null) {
            const oldActiveSlideElement = this.slides[this.activeSlide];
            const oldActiveSlideBullet = this.bullets[this.activeSlide];
            const oldActiveElements = [oldActiveSlideElement, oldActiveSlideBullet];

            oldActiveElements.forEach((element) => {
                element.classList.remove(
                    '_active',
                    '_active_up',
                    '_active_down',
                    '_inactive',
                    '_inactive_up',
                    '_inactive_down',
                );
                element.classList.add(
                    '_inactive',
                    `_inactive_${direction}`,
                );
            });
        }

        newActiveElements.forEach((element) => {

            element.classList.remove(
                '_active',
                '_active_up',
                '_active_down',
                '_inactive',
                '_inactive_up',
                '_inactive_down',
            );
            element.classList.add(
                '_active',
                `_active_${direction}`,
            );
        });

        this.activeSlide = newActiveSlide;
        this.activeBullet = newActiveSlide;
    }

    setPercent(element, percent, name)
    {
        if (!name) {
            name = 'percent';
        }
        element.style.setProperty(`--${name}`, `${percent}%`);
        element.style.setProperty(`--${name}-val`, percent);
        element.style.setProperty(`--${name}-rev`, `${100 - percent}%`);
        element.style.setProperty(`--${name}-rev-val`, 100 - percent);
    }
}