import { createApp } from 'vue';
import { createPinia } from "pinia";

import App from './App.vue';
import WidgetForm from "./components/WidgetForm.vue";
import maska from "maska";
const pinia = createPinia();

if (document.querySelector('#app')) {
    const app = createApp(App);
    app.mount('#app');
}

if (document.querySelector('#widget-form')) {
    const app = createApp(WidgetForm);
    app.use(pinia, maska);
    app.mount('#widget-form');
}