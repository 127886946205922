const header = document.querySelector('[data-header]');
const hamburger = document.querySelector('[data-header-hamburger]');
const menu = document.querySelector('[data-header-menu]');
let links = document.querySelectorAll('[data-drop-link]');

if (header && hamburger && menu) {
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('_opened');
        menu.classList.toggle('_opened');
    });

    links.forEach((link) => {
        link.addEventListener('click', ()=>{
            hamburger.classList.remove('_opened');
            menu.classList.remove('_opened');
        })
    });
}
