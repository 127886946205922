<template>
  <div class="widget-form__container widget-form__container_message">
    <div class="widget-form__head">
      <h4 class="widget-form__title">Приветственное сообщение</h4>
      <WidgetCheckbox id="message" v-model="message.checked"></WidgetCheckbox>
    </div>
    <div class="widget-form__links">
      <ul class="widget-form__fields widget-form__fields_message">

        <li class="widget-form__field-container">
          <span class="widget-form__field">
            <input
                type="text"
                v-model="message.text.value"
                id="whatsapp_phone"
                name="WhatsApp Phone"
                required="required"
                class="widget-form__input required"
                :disabled="!message.checked"
                @blur="validateField(message.text)"
                v-bind:class="{ _hasError: message.text.error }"
            >
            <label for="whatsapp_phone" class="widget-form__label text__field-label">
              Заголовок всплывающего окна (макс 30 символов)
            </label>
          </span>
          <ItemErrors :errors="message.text.error"/>
        </li>

        <li class="widget-form__field-container">
          <span class="widget-form__field">
            <input
                v-maska="'##'"
                type="text"
                v-model="message.timeout.value"
                id="whatsapp_phone"
                name="WhatsApp Phone"
                required="required"
                class="widget-form__input required"
                :disabled="!message.checked"
                @blur="validateField(message.timeout)"
                v-bind:class="{ _hasError: message.timeout.error }"
            >
             <label for="whatsapp_phone" class="widget-form__label text__field-label">
              Время активации сообщения, сек
            </label>
          </span>
          <ItemErrors :errors="message.timeout.error"/>
        </li>

      </ul>

    </div>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors.vue";
import WidgetCheckbox from "./WidgetCheckbox.vue";
import {mapWritableState} from "pinia";
import {useWidgetStore} from "../stores/modules/widget";
import {maska} from "maska";

export default {
  name: "WidgetMessage",
  components: {WidgetCheckbox, ItemErrors},
  computed: {
    ...mapWritableState(useWidgetStore, [
      "message"
    ]),
  },
  directives: {
    maska
  },
  methods: {
    validateField(component) {
      if (!component.value) {
        component.error = 'Обязательно для заполнения';
      } else {
        component.error = '';
      }
    }
  }
}
</script>

<style scoped>

</style>