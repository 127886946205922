import { defineStore } from 'pinia';
import widgetService from "../../services/widget.service";
export const useWidgetStore = defineStore(
    'widget',
    {
        state: () => {
            return {
                version: 'v1.0.4',
                whatsApp: {
                    checked: false,
                    value: '',
                    error: ''
                },
                mobilePhone: {
                    checked: false,
                    value: '',
                    error: ''
                },
                viberPhone: {
                    checked: false,
                    value: '',
                    error: ''
                },
                email: {
                    checked: false,
                    value: '',
                    error: ''
                },
                telegram: {
                    checked: false,
                    value: '',
                    error: ''
                },
                vk: {
                    checked: false,
                    value: '',
                    error: ''
                },
                position: {
                    sidePosition: 'right',
                    side: {
                        space: '30',
                        error: '',
                    },
                    bottom: {
                        space: '30',
                        error: '',
                    },
                },
                size: 'M',
                message: {
                    checked: false,
                    text: {
                        value: 'Привет! Какие задачи нужно решить? Свяжитесь с нами',
                        error: ''
                    },
                    timeout: {
                        value: '30',
                        error: ''
                    },
                },

                code: '',
                errors: {
                    notHaveLinks: '',
                },
            }
        },
        getters: {
            getCode(state) {
                return widgetService.prepareSettings(state);
            }
        },
    }
);
