<template>
  <div class="widget-form__container widget-form__container_size">
    <h4>Размер для десктопа</h4>
    <ul class="widget-form__fields widget-form__fields_position">
      <li class="widget-form__field-container">

        <div class="widget-form__radio-block widget-form__radio-block_size">
          <div class="widget-form__field-radio">
            <input
                type="radio"
                id="S"
                name="size"
                value="S"
                v-model="size"
            >
            <label for="S">S</label>
          </div>

          <div class="widget-form__field-radio">
            <input
                type="radio"
                id="M"
                name="size"
                value="M"
                v-model="size"
                checked
            >
            <label for="M">M</label>
          </div>

          <div class="widget-form__field-radio">
            <input
                type="radio"
                id="L"
                name="size"
                value="L"
                v-model="size"
            >
            <label for="L">L</label>
          </div>
        </div>

      </li>
    </ul>
    <div class="widget-form__example-block">

      <div class="widget-form__example-item">
        <div class="widget-form__example-icon widget-form__example-icon_small">
        </div>
        <div class="widget-form__example-size">
          S
        </div>
        <div class="widget-form__example-size-px">
          48x48 px
        </div>
      </div>

      <div class="widget-form__example-item">
        <div class="widget-form__example-icon widget-form__example-icon_medium">
        </div>
        <div class="widget-form__example-size">
          M
        </div>
        <div class="widget-form__example-size-px">
          58x58 px
        </div>
      </div>

      <div class="widget-form__example-item">
        <div class="widget-form__example-icon widget-form__example-icon_large">
        </div>
        <div class="widget-form__example-size">
          L
        </div>
        <div class="widget-form__example-size-px">
          68x68 px
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useWidgetStore} from "../stores/modules/widget";

export default {
  name: "WidgetSize",
  computed: {
    ...mapWritableState(useWidgetStore, {
      size: 'size'
    }),
  },
}
</script>

<style scoped>

</style>