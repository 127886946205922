<template>
  <div class="widget-form__container">
    <h4 class="widget-form__title">Положение виджета</h4>
    <ul class="widget-form__fields widget-form__fields_position">

      <li class="widget-form__field-container">
        <div class="widget-form__radio-block-label text__field-label">
          Расположение на странице
        </div>
        <div class="widget-form__radio-block widget-form__radio-block_position">
          <div class="widget-form__field-radio">
            <input
                type="radio"
                id="left"
                name="position"
                value="left"
                v-model="position.sidePosition"
            >
            <label for="left" class="text__size-radio">Слева</label>
          </div>

          <div class="widget-form__field-radio">
            <input
                type="radio"
                id="right"
                name="position"
                value="right"
                v-model="position.sidePosition"
                checked>
            <label for="right" class="text__size-radio">Справа</label>
          </div>
        </div>

      </li>

      <li class="widget-form__field-container">
        <div class="widget-form__field">
          <input
              type="text"
              id="side"
              class="widget-form__input required"
              v-model="position.side.space"
              v-maska="'###'"
              v-bind:class="{ _hasError: position.side.error }"
              @blur="validateField(position.side)"
          >
          <label for="side" class="widget-form__label text__field-label">Отступ сбоку, px </label>
        </div>
        <ItemErrors :errors="position.side.error"/>
      </li>

      <li class="widget-form__field-container">
        <div class="widget-form__field">
          <input
              type="text"
              id="bottom"
              class="widget-form__input required"
              v-model="position.bottom.space"
              v-maska="'###'"
              v-bind:class="{ _hasError: position.bottom.error }"
              @blur="validateField(position.bottom)"
          >
          <label for="bottom" class="widget-form__label text__field-label">Отступ снизу, px </label>
        </div>
        <ItemErrors :errors="position.bottom.error"/>
      </li>

    </ul>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useWidgetStore} from "../stores/modules/widget";
import { maska } from 'maska'
import ItemErrors from "./ItemErrors.vue";

export default {
  name: "WidgetPosition",
  components: {ItemErrors},
  computed: {
    ...mapWritableState(useWidgetStore, {
      position: 'position'
    }),
  },
  directives: {
    maska
  },
  methods: {
    validateField(component) {
      if (!component.space) {
        component.error = 'Обязательно для заполнения';
      } else {
        component.error = '';
      }
    }
  }
}
</script>

<style scoped>

</style>