<template>
  <div class="widget-form">
    <h2 class="widget-form__main-title">Сконфигурировать виджет</h2>
    <WidgetLinks/>
    <WidgetPosition/>
    <WidgetMessage/>
    <WidgetSize/>
    <WidgetResultArea/>
  </div>
</template>

<script>

import WidgetLinks from "./WidgetLinks.vue";
import WidgetResultArea from "./WidgetResultArea.vue";
import WidgetPosition from "./WidgetPosition.vue";
import WidgetSize from "./WidgetSize.vue";
import WidgetMessage from "./WidgetMessage.vue";

export default {
  name: "WidgetForm",
  components: {
    WidgetMessage,
    WidgetSize,
    WidgetPosition,
    WidgetLinks,
    WidgetResultArea
  }
}
</script>

<style scoped>

</style>