<template>
  <div class="widget-form-checkbox">
    <input type="checkbox"
           :id=id
           :checked="modelValue"
           @input="$emit('update:modelValue', $event.target.checked)"
           class="widget-form-checkbox__input"
    >
    <label :for=id class="widget-form-checkbox__label">
    </label>
  </div>
</template>

<script>
export default {
  name: "WidgetCheckbox",
  props: ['id', 'modelValue']
}
</script>

<style lang="scss">

</style>