<template>
  <div class="widget-form__container widget-form__container_result">
      <h4 class="widget-form__title">
        Установите код на сайт
      </h4>
    <ItemErrors :errors="errors.notHaveLinks"/>

    <div class="widget-form__result-description">
        Скопируйте код и разместите внутри тэга &lt;body&gt; на всех страницах сайта
      </div>
      <div class="widget-form__code"
           @dblclick="copyToClipboard()"
      >
        {{ this.code }}
      </div>

    <a class="widget-form__copy-button button button_violet"
       href="javascript:void(0);"
       @click="copyToClipboard()"
    >
      Скопировать код
    </a>
    <div class="widget-form__notify" data-notify>
      Текст скопирован в буфер обмена
    </div>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useWidgetStore} from "../stores/modules/widget";
import ItemErrors from "./ItemErrors.vue";

export default {
  name: "WidgetResultArea",
  components: {ItemErrors},
  computed: {
    ...mapWritableState(useWidgetStore, {
      code: 'getCode',
      errors: 'errors'
    }),
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.code);
        const notify = document.querySelector('[data-notify]');
        notify.classList.add('_show');
        setTimeout(() => {
          notify.classList.remove('_show');
        }, 2000)
      } catch (err) {
        console.error('Error in copying text: ', err);
      }
    }
  }
}
</script>

<style scoped>

</style>