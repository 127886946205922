const WidgetService = {
    preparePhoneForWhatsApp(phone) {
        if (phone) {
            let cleanPhone = phone.replace(/[^0-9.]/g, '');
            return `https://api.whatsapp.com/send?phone=${cleanPhone}`;
        }
    },
    preparePhoneForViber(phone) {
        if (phone) {
            let cleanPhone = phone.replace(/[^0-9.]/g, '');
            return `viber://contact?number=%2B${cleanPhone}`;
        }
    },
    prepareMobilePhone(phone) {
        if (phone) {
            let cleanPhone = phone.replace(/[^0-9.]/g, '');
            return `tel:${cleanPhone}`;
        }
    },
    prepareEmail(string) {
        if (string) {
            return `mailto:${string}`;
        }
    },
    prepareTelegram(string) {
        if (string) {
            return `https://t.me/${string}`;
        }
    },
    prepareVk(string) {
        if (string) {
            return `https://vk.me/${string}`;
        }
    },

    prepareSettings(state) {
        // Наполняем настройки на лету из стейта
        const settings = {};
            const version = document.querySelector('[data-version]').dataset.version;
            settings.version = version;
        if (state.position && !state.position.side.error && !state.position.bottom.error) {
            const sidePosition = state.position.sidePosition;
            settings[`${sidePosition}`] = state.position.side.space;
            settings.bottom = state.position.bottom.space;
        }
        if (state.size) {
            settings.size = state.size;
        }

        if (state.message.checked && !state.message.text.error && !state.message.timeout.error) {
            settings.message = {
                'text' : state.message.text.value,
                'timeout' : state.message.timeout.value
            }
        }

        const links = {};

        if (state.mobilePhone.value && !state.mobilePhone.error) {
            links.phone = this.prepareMobilePhone(state.mobilePhone.value);
        }
        if (state.whatsApp.value && !state.whatsApp.error) {
            links.whatsapp = this.preparePhoneForWhatsApp(state.whatsApp.value);
        }
        if (state.viberPhone.value && !state.viberPhone.error) {
            links.viber = this.preparePhoneForViber(state.viberPhone.value);
        }
        if (state.telegram.value) {
            links.telegram = this.prepareTelegram(state.telegram.value);
        }
        if (state.vk.value) {
            links.vk = this.prepareVk(state.vk.value);
        }
        if (state.email.value && !state.email.error) {
            links.mail = this.prepareEmail(state.email.value);
        }

        if (Object.keys(links).length !== 0) {
            settings.links = links;
            state.errors.notHaveLinks = '';
        } else {
            state.errors.notHaveLinks = 'Не заполнено ни одной ссылки';
            return '';
        }

        if (Object.keys(settings).length !== 0) {
            return this.prepareCode(settings);
        } else {
            return '';
        }
    },

    prepareCode(settings){
        return `<script> window.JustButtonSettings = ${JSON.stringify(settings, null, "\t")}</script>
<script type="text/javascript" src="https://knopka.rclass.pro/src/${settings.version}/main.min.js"></script>`;
    }
};

export default WidgetService;